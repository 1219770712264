<template>
  <div :class="{ active: navbarActive }" id="main-navigation">
    <div class="logo">
      <router-link to="/">
        <img src="@/assets/images/logo.svg" />
        <img class="onCollapsed" src="@/assets/images/logo-collapsed.svg" />
      </router-link>
      <div class="logo-actions">
        <a v-if="loggedIn" target="_blank" :href="$helper.buildShowOnWebUrl($Shops)">
          <i class="bi bi-globe"></i>
        </a>
        <i @click="toggleSidebar" class="bi bi-chevron-double-left"></i>
      </div>
    </div>
    <Navigation @close-navbar="$emit('close-navbar')" />
    <navbar-top />
  </div>
</template>

<script>
import { isUserLoggedIn } from "@/auth/utils";
import NavbarTop from "@/components/NavbarTop.vue";
import Navigation from "@/components/Navigation.vue";

export default {
  components: {
    NavbarTop,
    Navigation,
  },
  props: {
    navbarActive: { type: Boolean, default: false },
  },
  data() {
    return {
      loggedIn: isUserLoggedIn(),
    };
  },
  methods: {
    toggleSidebar() {
      this.$store.dispatch("toggleSidebar", false);
    },
  },
};
</script>
