/* eslint-disable no-unused-vars */
import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import { translator } from "@/libs/vuelidateMessages";
import { createHead } from "@vueuse/head";
import { createRouter } from "@/router";
import App from "./App.vue";
import store from "./store";
const app = createApp(App);
const router = createRouter(app);

// api prefix
let apiPrefix = "";
if (process.env.VUE_APP_PROD == "localhost") {
  apiPrefix = "eifela";
} else {
  apiPrefix = window.location.host.split(".")[0];
}

if (process.env.VUE_APP_API_POSTFIX == "localhost") {
  apiPrefix = "";
}

let apiURL = process.env.VUE_APP_API_PROTOCOL + apiPrefix + process.env.VUE_APP_API_POSTFIX;

if (window.location.host.includes("curaproxpartner")) {
  apiURL = process.env.VUE_APP_API_PROTOCOL + "curaprox" + process.env.VUE_APP_API_POSTFIX;
}

// bus
import $bus from "./event.js";
app.config.globalProperties.$bus = $bus;

import BootstrapVue3 from "bootstrap-vue-3";
// import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue-3/dist/bootstrap-vue-3.css";
import "bootstrap-icons/font/bootstrap-icons.css";
app.use(BootstrapVue3);

import FactoryRepository from "@/api/FactoryRepository";
import helperFunctions from "@/libs/helper";
import configHelperFunctions from "@/libs/configHelper";

import "@/libs/toastification";

import VueSSE from "vue-sse";

const head = createHead();
app.use(head);

/* language files */
import textSk from "@/locales/text-sk.json";

const messages = {
  sk: textSk,
};
const i18n = createI18n({
  locale: "sk",
  messages,
});
app.use(i18n);

app.use(translator, {
  messages,
  locale: i18n.global.locale,
});

// helper
app.use({
  install() {
    app.helper = helperFunctions;
    app.config.globalProperties.$helper = helperFunctions;
  },
});

// config helper
app.use({
  install() {
    app.configHelper = configHelperFunctions;
    app.config.globalProperties.$configHelper = configHelperFunctions;
  },
});

// JWT
import axios from "axios";
const axiosIns = axios.create({});
import JwtService from "@/auth/jwtService";
const JWT = new JwtService(axiosIns, apiURL);

app.use({
  install() {
    app.JWT = JWT;
    app.config.globalProperties.$JWT = JWT;
  },
});

// SSE
app.use(VueSSE);

// API Factory
FactoryRepository.init(JWT, apiURL);
app.use({
  install() {
    app.Articles = FactoryRepository.get("Articles");
    app.config.globalProperties.$Articles = FactoryRepository.get("Articles");

    app.Banners = FactoryRepository.get("Banners");
    app.config.globalProperties.$Banners = FactoryRepository.get("Banners");

    app.BannerSlides = FactoryRepository.get("BannerSlides");
    app.config.globalProperties.$BannerSlides = FactoryRepository.get("BannerSlides");

    app.Blocks = FactoryRepository.get("Blocks");
    app.config.globalProperties.$Blocks = FactoryRepository.get("Blocks");

    app.Carriers = FactoryRepository.get("Carriers");
    app.config.globalProperties.$Carriers = FactoryRepository.get("Carriers");

    app.Categories = FactoryRepository.get("Categories");
    app.config.globalProperties.$Categories = FactoryRepository.get("Categories");

    app.Configurations = FactoryRepository.get("Configurations");
    app.config.globalProperties.$Configurations = FactoryRepository.get("Configurations");

    app.Coupons = FactoryRepository.get("Coupons");
    app.config.globalProperties.$Coupons = FactoryRepository.get("Coupons");

    app.CustomFields = FactoryRepository.get("CustomFields");
    app.config.globalProperties.$CustomFields = FactoryRepository.get("CustomFields");

    app.CustomResources = FactoryRepository.get("CustomResources");
    app.config.globalProperties.$CustomResources = FactoryRepository.get("CustomResources");

    app.Discounts = FactoryRepository.get("Discounts");
    app.config.globalProperties.$Discounts = FactoryRepository.get("Discounts");

    app.Enquiries = FactoryRepository.get("Enquiries");
    app.config.globalProperties.$Enquiries = FactoryRepository.get("Enquiries");

    app.Enums = FactoryRepository.get("Enums");
    app.config.globalProperties.$Enums = FactoryRepository.get("Enums");

    app.Emails = FactoryRepository.get("Emails");
    app.config.globalProperties.$Emails = FactoryRepository.get("Emails");

    app.EmailTemplates = FactoryRepository.get("EmailTemplates");
    app.config.globalProperties.$EmailTemplates = FactoryRepository.get("EmailTemplates");

    app.Sms = FactoryRepository.get("Sms");
    app.config.globalProperties.$Sms = FactoryRepository.get("Sms");

    app.StockTrailProducts = FactoryRepository.get("StockTrailProducts");
    app.config.globalProperties.$StockTrailProducts = FactoryRepository.get("StockTrailProducts");

    app.StockTrailVariants = FactoryRepository.get("StockTrailVariants");
    app.config.globalProperties.$StockTrailVariants = FactoryRepository.get("StockTrailVariants");

    app.SmsTemplates = FactoryRepository.get("SmsTemplates");
    app.config.globalProperties.$SmsTemplates = FactoryRepository.get("SmsTemplates");

    app.Exports = FactoryRepository.get("Exports");
    app.config.globalProperties.$Exports = FactoryRepository.get("Exports");

    app.Establishments = FactoryRepository.get("Establishments");
    app.config.globalProperties.$Establishments = FactoryRepository.get("Establishments");

    app.Files = FactoryRepository.get("Files");
    app.config.globalProperties.$Files = FactoryRepository.get("Files");

    app.Imports = FactoryRepository.get("Imports");
    app.config.globalProperties.$Imports = FactoryRepository.get("Imports");

    app.Mercure = FactoryRepository.get("Mercure");
    app.config.globalProperties.$Mercure = FactoryRepository.get("Mercure");

    app.Navigations = FactoryRepository.get("Navigations");
    app.config.globalProperties.$Navigations = FactoryRepository.get("Navigations");

    app.Orders = FactoryRepository.get("Orders");
    app.config.globalProperties.$Orders = FactoryRepository.get("Orders");

    app.Parameters = FactoryRepository.get("Parameters");
    app.config.globalProperties.$Parameters = FactoryRepository.get("Parameters");

    app.ParameterItems = FactoryRepository.get("ParameterItems");
    app.config.globalProperties.$ParameterItems = FactoryRepository.get("ParameterItems");

    app.Payments = FactoryRepository.get("Payments");
    app.config.globalProperties.$Payments = FactoryRepository.get("Payments");

    app.Pdfs = FactoryRepository.get("Pdfs");
    app.config.globalProperties.$Pdfs = FactoryRepository.get("Pdfs");

    app.PickUpPoints = FactoryRepository.get("PickUpPoints");
    app.config.globalProperties.$PickUpPoints = FactoryRepository.get("PickUpPoints");

    app.PriceDefinitions = FactoryRepository.get("PriceDefinitions");
    app.config.globalProperties.$PriceDefinitions = FactoryRepository.get("PriceDefinitions");

    app.PriceLevels = FactoryRepository.get("PriceLevels");
    app.config.globalProperties.$PriceLevels = FactoryRepository.get("PriceLevels");

    app.Producers = FactoryRepository.get("Producers");
    app.config.globalProperties.$Producers = FactoryRepository.get("Producers");

    app.Products = FactoryRepository.get("Products");
    app.config.globalProperties.$Products = FactoryRepository.get("Products");

    app.ProductInformationParameters = FactoryRepository.get("ProductInformationParameters");
    app.config.globalProperties.$ProductInformationParameters = FactoryRepository.get("ProductInformationParameters");

    app.ProductVariants = FactoryRepository.get("ProductVariants");
    app.config.globalProperties.$ProductVariants = FactoryRepository.get("ProductVariants");

    app.ProductVariantInformationParameters = FactoryRepository.get("ProductVariantInformationParameters");
    app.config.globalProperties.$ProductVariantInformationParameters = FactoryRepository.get(
      "ProductVariantInformationParameters"
    );

    app.ProductQuantityPrices = FactoryRepository.get("ProductQuantityPrices");
    app.config.globalProperties.$ProductQuantityPrices = FactoryRepository.get("ProductQuantityPrices");

    app.ProductVariantQuantityPrices = FactoryRepository.get("ProductVariantQuantityPrices");
    app.config.globalProperties.$ProductVariantQuantityPrices = FactoryRepository.get("ProductVariantQuantityPrices");

    app.Reviews = FactoryRepository.get("Reviews");
    app.config.globalProperties.$Reviews = FactoryRepository.get("Reviews");

    app.Roles = FactoryRepository.get("Roles");
    app.config.globalProperties.$Roles = FactoryRepository.get("Roles");

    app.Root = FactoryRepository.get("Root");
    app.config.globalProperties.$Root = FactoryRepository.get("Root");

    app.Sets = FactoryRepository.get("Sets");
    app.config.globalProperties.$Sets = FactoryRepository.get("Sets");

    app.Shops = FactoryRepository.get("Shops");
    app.config.globalProperties.$Shops = FactoryRepository.get("Shops");

    app.Tags = FactoryRepository.get("Tags");
    app.config.globalProperties.$Tags = FactoryRepository.get("Tags");

    app.Users = FactoryRepository.get("Users");
    app.config.globalProperties.$Users = FactoryRepository.get("Users");

    app.UserInformationParameters = FactoryRepository.get("UserInformationParameters");
    app.config.globalProperties.$UserInformationParameters = FactoryRepository.get("UserInformationParameters");

    app.UserGroups = FactoryRepository.get("UserGroups");
    app.config.globalProperties.$UserGroups = FactoryRepository.get("UserGroups");

    app.Variables = FactoryRepository.get("Variables");
    app.config.globalProperties.$Variables = FactoryRepository.get("Variables");

    app.WebPages = FactoryRepository.get("WebPages");
    app.config.globalProperties.$WebPages = FactoryRepository.get("WebPages");

    app.WebTranslations = FactoryRepository.get("WebTranslations");
    app.config.globalProperties.$WebTranslations = FactoryRepository.get("WebTranslations");

    app.Zones = FactoryRepository.get("Zones");
    app.config.globalProperties.$Zones = FactoryRepository.get("Zones");

    app.GoogleProductCategories = FactoryRepository.get("GoogleProductCategories");
    app.config.globalProperties.$GoogleProductCategories = FactoryRepository.get("GoogleProductCategories");
  },
});

app.directive("click-outside", {
  mounted(el, binding) {
    el.clickOutsideEvent = function (event) {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event, el);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});

app.use(store);
app.use(router);

app.mount("#app");
