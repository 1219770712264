<template>
  <div class="layout-vertical" :class="[{ 'navbar-active': navbarActive }, { 'is-mobile': isMobile }]">
    <mobile-navbar v-if="isMobile" :navbar-active="navbarActive" />
    <Navbar v-model:navbarActive="navbarActive" @close-navbar="closeNavbar" />

    <div class="app-container">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";
import MobileNavbar from "@/components/MobileNavbar.vue";

export default {
  components: {
    Navbar,
    MobileNavbar,
  },
  data() {
    return {
      isMobile: null,
    };
  },
  computed: {
    navbarActive() {
      return this.$store.state.sidebarExpanded;
    },
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  beforeUnmount() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize);
    }
  },
  methods: {
    closeNavbar() {
      this.$store.dispatch("toggleSidebar", false);
    },
    setNavActivity(data) {
      this.navbarActive = data;
    },
    onResize() {
      this.isMobile = window.innerWidth <= 1024;
      if (window.innerWidth < 1199) {
        this.$store.dispatch("toggleSidebar", false);
      }
    },
  },
};
</script>
