import AbstractResource from "./AbstractResource";
import SerializationGroups from "./SerializationGroups.json";
import configHelper from "@/libs/configHelper";

export default class Resource extends AbstractResource {
  constructor(JWT, config) {
    super();
    this.apiURL = config.apiURL;
    this.resource = config.resource;
    this.JWT = JWT;
  }

  prepareURL(id = null, locale = null, priceDefinition = null, path = null) {
    let url = `${this.apiURL}/${this.resource}`;
    if (id) {
      if (path) {
        url = `${url}/${id}/${path}`;
      } else {
        url = `${url}/${id}`;
      }
    }

    let connector = "?";
    if (priceDefinition) {
      url = `${url}${connector}priceDefinition=${priceDefinition}`;
      connector = "&";
    }

    if (locale) {
      url = `${url}${connector}locale=${locale}`;
    }
    return url;
  }

  getName() {
    return `${this.resource}`;
  }

  getLocale() {
    if (configHelper.isShopMultilang()) {
      return localStorage.getItem("sellio_locale") || null;
    }
    return null;
  }

  expandSerializationGroup(parameters, serializationGroups, group, relations = "") {
    if (Array.isArray(group)) {
      parameters["properties"] = group;
    }
    if (serializationGroups[group]) {
      let columns = [];
      serializationGroups[group].forEach((property) => {
        if (property instanceof Object) {
          this.expandSerializationGroup(
            parameters,
            property,
            Object.keys(property)[0],
            relations + "[" + Object.keys(property)[0] + "]"
          );
        } else {
          columns.push(property);
        }
      });
      relations ? (parameters["properties" + relations] = columns) : (parameters["properties"] = columns);
    }
  }

  getCollection(parameters, serializationGroup = null) {
    const { params = {}, headers = {} } = parameters;
    const locale = this.getLocale();
    const url = this.prepareURL(null, locale);
    if (serializationGroup !== null) {
      this.expandSerializationGroup(params, SerializationGroups, serializationGroup);
    }
    return this.JWT.axiosIns.get(url, { params, headers });
  }

  getResource(parameters, serializationGroup = null) {
    const { id, params = {}, headers = {} } = parameters;
    let locale = this.getLocale();
    if (params.locale) {
      locale = params.locale;
      delete params.locale;
    }
    const url = this.prepareURL(id, locale);
    if (serializationGroup !== null) {
      this.expandSerializationGroup(params, SerializationGroups, serializationGroup);
    }
    return this.JWT.axiosIns.get(url, { params, headers });
  }

  createResource(parameters) {
    const { body, params = {}, headers = {} } = parameters;
    let locale = this.getLocale();
    if (body.locale) {
      locale = body.locale;
      delete body.locale;
    }

    let priceDefinition = null;
    if (body.priceDefinition && this.getName() !== "shops") {
      priceDefinition = body.priceDefinition;
      delete body.priceDefinition;
    }
    const url = this.prepareURL(null, locale, priceDefinition);
    return this.JWT.axiosIns.post(url, body, { params, headers });
  }

  updateResource(parameters) {
    const { id, body, params = {}, headers = {} } = parameters;
    let locale = this.getLocale();
    if (body.locale) {
      locale = body.locale;
      delete body.locale;
    }
    const url = this.prepareURL(id, locale);
    headers["Content-Type"] = "application/merge-patch+json";
    return this.JWT.axiosIns.patch(url, body, { params, headers });
  }

  deleteResource(parameters) {
    const { id, params = {}, headers = {} } = parameters;
    const url = this.prepareURL();
    return this.JWT.axiosIns.delete(`${url}/${id}`, { params, headers });
  }

  getResourceFiles(parameters) {
    const { id, params = {}, headers = {} } = parameters;
    let locale = this.getLocale();
    if (params.locale) {
      locale = params.locale;
      delete params.locale;
    }
    const url = this.prepareURL(id, locale, null, "files");
    return this.JWT.axiosIns.get(url, { params, headers });
  }

  getResourceUsers(parameters, serializationGroup = null) {
    const { id, params = {}, headers = {} } = parameters;
    const url = this.prepareURL();
    if (serializationGroup !== null) {
      this.expandSerializationGroup(params, SerializationGroups, serializationGroup);
    }
    return this.JWT.axiosIns.get(`${url}/${id}/users`, { params, headers });
  }

  getResourceByUrl(parameters, serializationGroup = null) {
    const { url, params = {}, headers = {} } = parameters;
    if (serializationGroup !== null) {
      this.expandSerializationGroup(params, SerializationGroups, serializationGroup);
    }
    let finalUrl = `${this.apiURL}${url}`;
    let locale = this.getLocale();
    if (params.locale) {
      locale = params.locale;
      delete params.locale;
    }
    if (locale) {
      finalUrl = `${finalUrl}?locale=${locale}`;
    }
    return this.JWT.axiosIns.get(finalUrl, { params, headers });
  }

  createResourceByUrl(parameters) {
    const { url, body = {}, params = {}, headers = {} } = parameters;
    let finalUrl = `${this.apiURL}${url}`;
    let locale = this.getLocale();
    if (body.locale) {
      locale = body.locale;
      delete body.locale;
    }
    if (locale) {
      finalUrl = `${finalUrl}?locale=${locale}`;
    }
    return this.JWT.axiosIns.post(finalUrl, body, {
      params,
      headers,
    });
  }

  updateResourceByUrl(parameters) {
    const { url, body, params = {}, headers = {} } = parameters;
    headers["Content-Type"] = "application/merge-patch+json";
    let finalUrl = `${this.apiURL}${url}`;
    let locale = this.getLocale();
    if (body.locale) {
      locale = body.locale;
      delete body.locale;
    }
    if (locale) {
      finalUrl = `${finalUrl}?locale=${locale}`;
    }
    return this.JWT.axiosIns.patch(finalUrl, body, {
      params,
      headers,
    });
  }

  deleteResourceByUrl(parameters) {
    const { url, params = {}, headers = {} } = parameters;
    return this.JWT.axiosIns.delete(`${this.apiURL}${url}`, {
      params,
      headers,
    });
  }

  getResourceBySlug(slug, params = {}, serializationGroup = null) {
    const url = this.prepareURL();
    let finalUrl = `${url}/url/${slug}`;
    const locale = this.getLocale();
    if (locale && !params.locale) {
      finalUrl = `${finalUrl}?locale=${locale}`;
    }
    if (serializationGroup !== null) {
      this.expandSerializationGroup(params, SerializationGroups, serializationGroup);
    }
    return this.JWT.axiosIns.get(finalUrl, { params });
  }
}
